<template>
  <div>
    <p class="product-disclaimer">Some grant funds are limited and if they run out the grant will end sooner than the closing date.</p>
    <div class="product-offerings-list">
      <div class="product-offering" v-for="product in products" :key="product.opportunityId">
        <div class="product-offering-image">
          <img :src="product.logoUri" v-if="product.logoUri" alt="Product logo"/>
          {{ product.providerName }}
        </div>
        <div class="product-offering-information">
          <div class="information-box centered-md">
            <span class="product-offering-amount">{{ fundingRequired | numberSeparator | currency }}</span>
            <span class="product-offering-provider">{{ product.productName }}</span>
          </div>
          <div class="information-box centered-md">
            <span class="product-offering-general-bold">{{ product.minSize | numberSeparator | currency }} - {{ product.maxSize | numberSeparator | currency  }}</span>
            <span class="product-offering-general">Grant range</span>
          </div>
          <div class="information-box centered-md">
            <span class="product-offering-general-bold">{{ formatDate(product.closingDate) }}</span>
            <span class="product-offering-general">Closing date</span>
          </div>
          <div class="btn btn-primary" @click="$emit('view-product-offering', product)">
            See more
          </div>
        </div>
      </div>
      <div v-if="!Array.isArray(products) || !products.length">
        <h1>No matching products found</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductOfferingList',
  props: {
    fundingRequired: {
      type: [String, Number],
      required: true
    },
    products: {
      type: Array
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return ''
      return new Intl.DateTimeFormat('en-GB').format(new Date(date))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';
.product-disclaimer {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 4px;
}

.product-offerings-list {
  @media only screen and (max-width: $breakpoint-sm) {
    display: flex;
    flex-wrap: wrap;
  }
  .product-offering {
    display: flex;
    flex-direction: row;
    min-height: 118px;
    border: 1px solid var(--color-secondary-50);
    background-color: $color-white;
    border-radius: 8px;
    margin-bottom: 16px;
    transition: all 0.5s;
    width: 100%;

    @media only screen and (max-width: 600px) {
      height: 100%;
      flex-direction: column;
      margin: 8px;
    }

    &:hover {
      border-color: var(--color-secondary-200);
      box-shadow: $lift-box-shadow;
      transform: scale(1.01);
    }

    .product-offering-image {
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid var(--color-secondary-50);
      flex-direction: column;
      font-size: 12px;
      font-weight: 500;
      width: 280px;

      @media only screen and (max-width: 600px) {
        padding: 24px 12px 0 12px;
        width: 100%;
        border-right: 0;
      }

      img {
        width: 140px;

        @media only screen and (max-width: $breakpoint-sm) {
          width: 200px;
        }
      }
    }

    .product-offering-information {
      width: 100%;
      padding: 20px 40px;
      display: flex;
      justify-content: space-between;

      @media only screen and (max-width: $breakpoint-sm) {
        padding: 8px;
        flex-direction: column;
        justify-content: center;
        align-items: start;
      }

      .information-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 25%;

        &.centered-md {
          justify-content: center;
          @media only screen and (max-width: $breakpoint-sm) {
            justify-content: space-between;
          }
        }

        @media only screen and (max-width: $breakpoint-sm) {
          width: 100%;
          flex-direction: row-reverse;
          justify-content: space-between;
          margin: 4px 0;
        }

        span {
          margin: 2px 0;
        }

        span.product-offering-provider {
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          margin-right: 16px;
          min-width: 105px;

          @media only screen and (max-width: $breakpoint-sm) {
            min-width: 0;
          }
        }
        span.product-offering-amount {
          font-size: 24px;
          font-weight: 500;
          color: var(--color-primary-500);
        }
        span.product-offering-category {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          border-radius: 16px;
          color: $color-royal-purple;
          border: 1px solid $color-royal-purple;
          width: 120px;

          &.innovation-grant {
            color: var(--color-primary-400);
            border-color: var(--color-primary-300);
          }
        }
        span.product-offering-general-bold {
          font-size: 20px;
          font-weight: 500;
          color: var(--color-primary-500);
        }
        span.product-offering-general {
          font-size: 14px;
          font-weight: 500;
        }
      }

      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        line-height: 16px;
        width: 25%;

        @media only screen and (max-width: $breakpoint-sm) {
          width: 100%;
          margin-top: 8px;
        }
      }
    }
  }
}
</style>
