<template>
  <div class="relative-wrapper">
    <Navbar />
    <div class="page-wrapper">
      <div class="container content">
        <div class="ui active inverted dimmer page-loader" v-if="loading">
          <div class="ui medium text loader"><spinner :size="64" :loader="true" color /></div>
        </div>
        <BackBtn />
        <h1 class="product-offering-title">Grants matches</h1>
        <p class="product-offering-subtitle">Here are the top grants relevant for your business, you can apply for as many as you like.</p>
        <ProductOfferingList :products="products" :funding-required="formData.totalFundingRequired" @view-product-offering="openDetailsPage" v-if="formData && !loading" />
        <button class="btn btn-transparent see-more" v-if="page < totalPages" @click="fetchGrants">View more grants</button>
      </div>
      <div class="container">
        <Footer />
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import { amplitudeEvents } from '@/utils/constants'
import { getGrants } from '@product/api/products'
import Navbar from '../components/Navbar'
import BackBtn from '@/components/BackBtn'
import ProductOfferingList from '@/components/ProductOfferingList'
import Spinner from '@/components/Spinner'
import Footer from '@/components/Footer'
import checkGrantFinderRedirects from '../mixins/checkGrantFinderRedirects'

export default {
  name: 'Grants',
  mixins: [checkGrantFinderRedirects],
  components: { ProductOfferingList, BackBtn, Spinner, Navbar, Footer },
  data() {
    return {
      page: 0,
      perPage: 3,
      totalPages: 0,
      products: [],
      formData: this.getFormData(),
      loading: true,
      detailsPageOpen: false
    }
  },
  created() {
    this.redirectToForm()
    this.fetchGrants()
  },
  mounted() {
    this.trackPageView()
  },
  methods: {
    getFormData() {
      const formData = localStorage.getItem('formData')

      return formData ? JSON.parse(formData) : null
    },
    redirectToForm() {
      if (!this.formData) {
        this.$router.push({ name: 'lloyds-form' })
      }
    },
    fetchGrants() {
      this.loading = true
      getGrants({ ...this.formData, take: this.perPage, skip: this.page * this.perPage })
        .then(({ data: { opportunities, pagination } }) => {
          this.products = [...opportunities]
          this.totalPages = Math.ceil(pagination.total / pagination.take)
          this.page = this.page + 1
        })
        .catch(error => console.error(error))
        .finally(() => {
          this.loading = false
        })
    },
    trackCTAEvent(product) {
      this.$ma.trackEvent({
        eventType: amplitudeEvents.lloydsSMEGrants.GRANTS_PRODUCT_SELECTED,
        eventProperties: {
          providerName: product.providerName,
          productName: product.productName
        }
      })
    },
    trackPageView() {
      this.$ma.trackEvent(amplitudeEvents.lloydsSMEGrants.LANDING_PAGE_VIEW_GRANTS_MATCHES)
    },
    openDetailsPage(product) {
      this.$router.push({ name: 'lloyds-grants-overlay', params: { id: product.opportunityId } })
      this.trackCTAEvent(product)
    },
    closeDetailsPage() {
      this.$router.push({ name: 'lloyds-grants' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.relative-wrapper {
  position: relative;
  padding: 0 10px;
}

.page-wrapper {
  position: relative;

  .container {
    padding-top: 24px;

    &.content {
      min-height: 250px;
    }

    .helper-text {
      display: flex;
      justify-content: space-between;
    }

    h1.product-offering-title {
      margin-top: 18px;
      color: var(--color-primary-500);
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 0;

      &:before {
        content: '';
        border-left: 2px solid var(--color-secondary-500);
        margin-right: 8px;
      }
    }

    p.product-offering-subtitle {
      margin: 8px 0;
    }

    button.see-more {
      width: 100%;
      font-size: 12px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--color-primary-500);
      margin-top: 32px;
      border: 1px solid var(--color-secondary-50);

      &:hover {
        border-color: var(--color-primary-400);
        background-color: var(--color-secondary-50);
        color: var(--color-primary-500);
      }
    }
  }
}
</style>
